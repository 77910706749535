<template>
    <div v-if="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
    </div>
    <div v-else>
        <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="vehicleType.type">{{vehicleType.type}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>New vehicle type</h3>
           
            <md-button class="md-raised" @click="addPicture">Add image</md-button>
            <!--md-button class="md-primary">Save vehicle type</md-button-->
            <md-button class="md-primary md-raised" @click="fireUpdate">Update vehicle type</md-button>
        </md-toolbar>
        <div class="main-inner-wrapper">
            <CRow :gutters="true">
                <CCol sm="12" lg="5">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Vehicle info</div>
                        </md-card-header>

                        <md-card-content>
                    
                            <CRow>
                                <CCol sm="12">
                                    <md-field :class="getValidationClass('type')">
                                        <label>Name</label>
                                        <md-input v-model="vehicleType.type"></md-input>
                                        <span class="md-error" v-if="!$v.vehicleType.type.required">Base is required</span>
                                    </md-field>
                                    <md-field>
                                        <label>Description</label>
                                        <md-textarea v-model="vehicleType.description" md-counter="250"></md-textarea>
                                    </md-field>
                                    <md-field :class="getValidationClass('base')">
                                        <md-select v-model="vehicleType.base" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type">
                                            <md-option v-for="vehicleType in baseVehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.name}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.vehicleType.base.required">Vehicle Type is required</span>
                                    </md-field>
                                </CCol>
                            </CRow>
                            <CRow>
                            
                                <CCol sm="6">
                                    <md-field :class="getValidationClass('passengers')">
                                        <md-icon>people</md-icon>
                                        <label>Passengers</label>
                                        <md-input v-model="vehicleType.passengers" type="number" :max="selectedBase.passengers" :disabled="!selectedBase.passengers"></md-input>
                                        <span class="md-error" v-if="!$v.vehicleType.passengers.required">Required</span>
                                        <span class="md-error" v-if="!$v.vehicleType.passengers.between">Number must be between 1 and {{selectedBase.passengers}}</span>
                                    </md-field>
                                </CCol>
                                <CCol sm="6">
                                    <md-field :class="getValidationClass('luggage')">
                                        <md-icon>luggage</md-icon>
                                        <label>Luggage</label>
                                        <md-input v-model="vehicleType.luggage" type="number" :max="selectedBase.luggage" :disabled="!selectedBase.luggage"></md-input>
                                        <span class="md-error" v-if="!$v.vehicleType.luggage.required">Required</span>
                                        <span class="md-error" v-if="!$v.vehicleType.luggage.between">Number must be between 1 and {{selectedBase.luggage}}</span>
                                    </md-field>
                                </CCol>
                                
                            </CRow>
                            <md-divider class="mt-3"></md-divider>
                            <CRow >
                                <CCol sm="12">
                                    <md-switch v-model="vehicleType.visible" class="md-primary">Visible</md-switch>
                                </CCol>
                            </CRow>
                            <CRow >
                                <CCol sm="12">
                                    <md-button class="md-raised md-accent full-width-button " @click="destroy">Delete vehicle type</md-button>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12">
                                    <md-field class="d-none">
                                        <label for="vehicle-pictures">Vehicle images</label>
                                        <md-file ref="carfile" multiple="multiple" name="vehicle-pictures" id="vehicle-pictures" :disabled="sending" @change="imageChanged($event, 'car-picture')"/>
                                    </md-field>
                                    
                                </CCol>
                            </CRow>
                        </md-card-content>
                        
                    </md-card>
                </CCol>
                <CCol sm="12" lg="7">
                    <md-card>
                        <div v-if="vehicleType.pictures.length > 0">
                            <md-card-header >
                                    <div class="md-title">Vehicle images</div>
                            </md-card-header>
                            
                            <md-card-content v-if="vehicleType.pictures && vehicleType.pictures.length > 0" >
                                <p class="mb-0">Showing image <b>{{activeIndex + 1}}</b> of <b>{{vehicleType.pictures.length + files.length}}</b></p>
                                
                                    
                                    <content-loader v-if="isBusy"></content-loader>
                                    <div v-else>
                                            <carousel 
                                            :per-page="1" 
                                            :mouse-drag="true"  
                                            paginationActiveColor="#f9ba00" 
                                            paginationColor="#c3c3c3" 
                                            paginationPosition="top" 
                                            :adjustableHeight="false"
                                            
                                            ref="carousel" @pageChange="carouselChanged" >
                                                <slide v-for="(picture,index) in vehicleType.pictures" v-bind:key="picture.id">
                                                    <img :src="picture" alt="">
                                                    <md-button class="md-raised" @click="removePicture(picture, false,index)">Remove image</md-button>
                                                    
                                                </slide>
                                                <slide v-for="(newPicture,index) in files" v-bind:key="newPicture.id">
                                                    <vue-cropper 
                                                    
                                                        :src="newPicture.imgSrc"
                                                        :aspect-ratio="16 / 9"     
                                                        ref="cropper" 
                                                        >
                                                        
                                                    </vue-cropper>
                                                    <div class="d-flex justify-content-start buttons-container mt-3">
                                                        <div class="d-inline-flex">
                                                            <md-button class="md-dense md-raised" @click="zoomIn(index)"><md-icon>zoom_in</md-icon></md-button>
                                                            <md-button class="md-dense md-raised" @click="zoomOut(index)"><md-icon>zoom_out</md-icon></md-button>
                                                        </div>
                                                        <div class="d-inline-flex">
                                                            <md-button class="md-dense md-raised" @click="flip(index)"><md-icon>flip</md-icon></md-button>
                                                        </div>

                                                        <div class="d-inline-flex">
                                                            <md-button class="md-dense md-raised" @click="move(-10,0,index)"><md-icon>arrow_back</md-icon></md-button>
                                                            <md-button class="md-dense md-raised" @click="move(10,0,index)"><md-icon>arrow_forward</md-icon></md-button>
                                                            <md-button class="md-dense md-raised" @click="move(0,-10,index)"><md-icon>arrow_upward</md-icon></md-button>
                                                            <md-button class="md-dense md-raised" @click="move(0,10,index)"><md-icon>arrow_downward</md-icon></md-button>
                                                        </div>
                                                        <md-button class="md-raised md-dense position-relative" :class="{ 'remove-button': files.length > 1 }" @click="removePicture(newPicture,true,index)"><md-icon class="mr-2">delete</md-icon>Remove image <b>#{{activeIndex + 1}}</b></md-button>
                                                    </div>
                                                </slide>
                                            </carousel>


                                            

                                            
                                            
                                    </div>
                                
                                
                            </md-card-content>
                            
                        </div>
                        <md-card-content v-else>
                            <md-empty-state
                                md-icon="photo_library"
                                md-label="Add vehicle pictures here"
                                md-description="Car pictures will be display in your booking system">
                                <md-button class="md-primary md-raised" @click="addPicture">Add image</md-button>
                            </md-empty-state>
                            <md-field :class="getValidationClass('files')">
                                <span class="md-error" v-if="!$v.files.required">Add at least one image</span>
                            </md-field>
                        </md-card-content>
                    </md-card>
                </CCol>
            </CRow>
        </div>
         <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
            <span>{{snackMessage}}</span>
            <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
        </md-snackbar>
    </div>
</template>

<script>
import firebase from '../../Firebase'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { validationMixin } from 'vuelidate'
  import {
    required,
    email,
    minLength,
    between,
    maxLength
  } from 'vuelidate/lib/validators'

import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'VehicleType',
  components: {
     ContentLoader,
     VueCropper
  },
  mixins: [validationMixin],
  
  data() {
    return {
      showSnackbar: false,
      position: 'center',
      duration: 7000,
      isInfinity: false,
      snackMessage: '',
      activeIndex: 0,
      sending: false,
      files: [],
      baseVehicleTypes: [],
      vehicleType: null,
      isBusy: true,
      selectedBase: {
        passengers: null,
        luggage: null
    },
      //ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('vehicle-types')
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
      
  },
  watch: {
    'vehicleType.base': function(id){
        console.log(id)
        for (let i = 0; i < this.baseVehicleTypes.length; i++) {
            if(id == this.baseVehicleTypes[i].id) {
                this.selectedBase.passengers = this.baseVehicleTypes[i].passengers;
                this.selectedBase.luggage = this.baseVehicleTypes[i].luggage;
            }
            
        }
    }
},
  created () {
    let th = this;
    
    firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types').doc(this.$route.params.id).get()
      .then(function(doc) {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            th.vehicleType = doc.data();
            th.imagesLoaded()
            //th.isBusy = false
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });

    this.$firebase.firestore().collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
        this.baseVehicleTypes = [];
        querySnapshot.forEach((doc) => {
            
            let dataToSave = doc.data();
            dataToSave.id = doc.id ;
            this.baseVehicleTypes.push(dataToSave);
            
        });
    });

    


  },
  methods: {
    zoomIn(index){
        this.$refs.cropper[index].relativeZoom(0.1);
        
    },
    zoomOut(index){
        this.$refs.cropper[index].relativeZoom(-0.1);
    },
    flip(index){
        
        const dom =  this.$refs.cropper[index].cropper.canvas;
        let scale = dom.getAttribute('data-scale');
        scale = scale ? -scale : -1;
        this.$refs.cropper[index].scaleX(scale);
        dom.setAttribute('data-scale', scale);
    },
    move(offsetX, offsetY,index) {
        this.$refs.cropper[index].move(offsetX, offsetY);
    },

    imagesLoaded(){
        console.log(this.vehicleType.pictures);
        const images = this.vehicleType.pictures.map(imageSrc => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = imageSrc;
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        Promise.all(images).then(() => { 
            console.log("Images loaded!");
            this.isBusy = false;
        }).catch(error => {
            console.error("Some image(s) failed loading!");
            console.error(error.message)
            this.isBusy = false;
        });
    },
    carouselChanged(index){
        console.log(this.$refs.carousel);
        this.activeIndex = index;
    },
    async fireUpdate(){
        this.isBusy = true;
        if(this.files.length > 0) {
            await this.uploadPictures()
            this.update()
        } else {
            this.update()
        }
    },
    update() {
      
      let th = this;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types').doc(this.$route.params.id).update(th.vehicleType)
      .then(function(){
        console.log('Updated');
        th.snackMessage = 'Vehicle type updated';
        th.isBusy = false;
        th.showSnackbar = true;
        //th.$router.push({path: `/app/vehicle-types`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    },
    async destroy() {
      this.isBusy = true ;
      let th = this;
      await this.removeAllPictures()
      console.log('finish removing all pictures');
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types').doc(this.$route.params.id).delete()
      .then(function(){
        console.log('Deleted');
        th.$router.push({path: `/app/vehicle-types`})
        th.isBusy = false ;
      }).catch(function(error) {
          console.log("Error getting document:", error);
          th.isBusy = false ;
      });;
    },
    getCanvasBlob(canvas) {
        return new Promise(function(resolve, reject) {
            canvas.toBlob(function(blob) {
            resolve(blob)
            })
        })
    },
    async uploadPictures() {
        let email = this.user.data.email ;
        
        
        const promises = this.files.map(async (file, index) => {
            console.log(this.$refs.cropper[index]);
            const canvas = this.$refs.cropper[index].getCroppedCanvas()
            let ref = this.$firebase.storage().ref()
                        .child(`users/`+ email +`/vehicles/${file.file.name}`)
            var blobOutput = await this.getCanvasBlob(canvas);

            
            return ref.put(blobOutput).then(() => ref.getDownloadURL());
            
        })

        return Promise.all(promises)
        .then((uploadedMediaList) => {
            console.log(uploadedMediaList, 'all');
            this.files = [];
            for (let i = 0; i < uploadedMediaList.length; i++) {
                this.vehicleType.pictures.push(uploadedMediaList[i]);
            }
             
        })
        .catch((err) => alert(err.code));

    },
    async removeAllPictures() {
      let email = this.user.data.email ;
      const promises = this.vehicleType.pictures.map((picture, index) => {
            console.log(picture);
            let pictureRef = this.$firebase.storage().refFromURL(picture);
            return pictureRef.delete()
              .then(() => {
                console.log('deleted successfully');
              })
              .catch((err) => {
                console.log(err);
              });
      })
      return Promise.all(promises)
        .then((uploadedMediaList) => {
            console.log(uploadedMediaList, 'all');
            
        })
        .catch((err) => alert(err.code));

    },
    removePicture(picture,isNew,index){
        console.log(picture);
        console.log(isNew);
        console.log(index);
        if(isNew) {
            this.files.splice(index,1)
        } else {
            let url = this.vehicleType.pictures[index];
            let pictureRef = this.$firebase.storage().refFromURL(url);
            console.log(pictureRef)

            
            pictureRef.delete()
              .then(() => {
                console.log('deleted successfully');
                this.vehicleType.pictures.splice(index,1);
                this.update();

              })
              .catch((err) => {
                console.log(err);
                this.vehicleType.pictures.splice(index,1);
                this.update();
              });

        }
        
          
    },
    addPicture()
    {
        this.$refs.carfile.$refs.inputFile.click();
        
    },
    getValidationClass (fieldName) {
        let field = null;

        if(fieldName === 'files') {
            field = this.$v[fieldName];
        } else {
            field = this.$v.vehicleType[fieldName];
        }
        
        
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    validate(){
        let type,passengers,luggage,pictures,base;
        type = this.$v.vehicleType.type.$invalid;
        passengers = this.$v.vehicleType.passengers.$invalid;
        luggage = this.$v.vehicleType.luggage.$invalid;
        base = this.$v.vehicleType.base.$invalid;
        pictures = this.$v.files.$invalid;

        return !type && !passengers && !luggage && !pictures && !base ? true : false ;


    },
    imageChanged(e, type) {
        console.log(e.target.files);
        for (let i = 0; i < e.target.files.length; i++) {
                
            const file = e.target.files[i];
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {  
                    this.files.push({
                        imgSrc: event.target.result,
                        file: file
                    })
                    
                };
                reader.readAsDataURL(file);
            } else {
                alert('Sorry, FileReader API not supported');
            }
        }

        this.$refs.carfile.$refs.inputFile.value = '';
        
        //this.uploadPictures()
    
    }
  },
  validations: {
        files: {
            required,
            minLength: minLength(1),
        },
        vehicleType: {
            base: {
                required
            },
            type: {
                required
            },
            passengers: {
                required,
                between (value) {
                    return between(1, this.selectedBase.passengers)(value)
                }
            },
            luggage: {
                required,
                between (value) {
                    return between(1, this.selectedBase.luggage)(value)
                }
            },
            pictures: {
                required,
                minLength: minLength(1),
            }
        }
    }
}

</script>
<style>
.buttons-container .md-button{
    min-width: initial;
}
.VueCarousel-pagination {
    text-align: left!important;
}

.VueCarousel-slide-adjustableHeight {
    display: block !important;
}
.VueCarousel-inner {
    align-items: flex-start !important;
}

.VueCarousel-dot-container {
    margin-top: 0px!important;
}

.VueCarousel-dot {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}

img[lazy=loading] {
    /*your style here*/
    height: 300px;
  }
</style>

